'use client';

import React, { useEffect } from 'react';
import queryString from 'query-string';
import useToaster from '#lib/hooks/useToaster';
import useModal from '#lib/hooks/useModal';
import { useRouter, useSearchParams } from 'next/navigation';
import FailureNaverLoginPopup from '#components/auth/FailureNaverLoginPopup';
import { type ToasterType } from '@miniintern/ui';

const LoginErrorHandleCompenent = () => {
  const router = useRouter();
  const searchParams = useSearchParams();
  const message = searchParams?.get('message');
  const type = searchParams?.get('type');
  const provider = searchParams?.get('provider');

  const { openToast } = useToaster();
  const { openModal } = useModal();

  const redirectCurrentPage = () => {
    //* 회원가입 페이지에선 현재 탭 유지를 위해 parsedUrl.query.step를 보존한다.
    const parsedUrl = queryString.parseUrl(window.location.href);
    if (parsedUrl.query.step) {
      return router.push(`${parsedUrl.url}?step=${parsedUrl.query.step}`);
    }
    return router.push(parsedUrl.url);
  };

  useEffect(() => {
    if (message === 'Canceled By User' || message === 'Permissions error') {
      // 로그인 취소시 예외 처리
      redirectCurrentPage();
      return;
    }
    if (provider === 'naver' && message === '필수 제공 항목에 동의해 주세요') {
      const redirect = () => {
        redirectCurrentPage();
        openModal({
          Component: () => <FailureNaverLoginPopup />,
        });
      };
      redirect();
      return;
    }

    if (message) {
      openToast({
        message: String(message),
        type: (type as ToasterType) ?? 'error',
        callback: redirectCurrentPage,
      });
    }
  }, [message, provider]);

  return <div></div>;
};

export default LoginErrorHandleCompenent;
