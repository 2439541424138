import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SkillupListQueries } from '#types/apiQueries';
import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { sendErrorToSentry } from '#lib/error';
import { getSkillupListAPI } from '#lib/api/skillup';

import {
  SkillupLecture,
  SkillupCourse,
  CourseDetail,
  SkillupList,
  SetSkillupCurrentLecturePayload,
} from '#types/skillup';

function updateCourseLectures(
  state: SkillupState,
  lectureId: number,
  updatedLecture: SkillupLecture,
) {
  if (state.course) {
    state.course = {
      ...state.course,
      chapter: state.course.chapter.map(chapter => ({
        ...chapter,
        lecture: chapter.lecture.map(lecture =>
          lecture.lectureId === lectureId ? updatedLecture : lecture,
        ),
      })),
    };
  }
}

export type SkillupState = {
  detail: CourseDetail | null;
  list: SkillupList & {
    isLoading: boolean;
    queries: SkillupListQueries | null;
  };
  course: SkillupCourse | null;
  currentLecture: SkillupLecture | null;
};

const initialState: SkillupState = {
  detail: null,
  list: {
    count: 0,
    courseList: [],
    isLoading: true,
    queries: null,
  },
  course: null,
  currentLecture: null,
};

const skillup = createSlice({
  name: 'skillup',
  initialState,
  reducers: {
    setSkillupDetail(state, action) {
      state.detail = action.payload;
    },
    setSkillupList_Request(state, action: PayloadAction<SkillupListQueries>) {
      state.list.isLoading = true;
      if (action.payload.page === '1') {
        state.list.count = initialState.list.count;
        state.list.courseList = initialState.list.courseList;
      }
      state.list.queries = action.payload;
    },
    setSkillupList_Success(state, action: PayloadAction<SkillupList>) {
      state.list.isLoading = false;
      state.list.count = action.payload.count;
      state.list.courseList = [...state.list.courseList, ...action.payload.courseList];
    },
    setSkillupList_Failure(state) {
      state.list.isLoading = false;
      state.list.queries = null;
    },
    setSkillupCourse(state, action: PayloadAction<SkillupCourse>) {
      state.course = action.payload;
    },
    resetSkillupCourse(state) {
      state.course = null;
      state.currentLecture = null;
    },
    setSkillupCurrentLecture(state, action: PayloadAction<SetSkillupCurrentLecturePayload>) {
      state.currentLecture = action.payload.currentLecture;
      if (action.payload.doUpdateCourse) {
        updateCourseLectures(state, state.currentLecture.lectureId, state.currentLecture);
      }
    },
    setSkillupLecturePlayedTime(state, action: PayloadAction<number>) {
      if (!state.currentLecture) return;
      const videoOrYoutube = state.currentLecture?.video || state.currentLecture?.youtube;
      if (!videoOrYoutube) return;
      videoOrYoutube.playedTime = action.payload;
      updateCourseLectures(state, state.currentLecture.lectureId, state.currentLecture);
    },
    setSkillupLectureRealPlayedTime(state, action: PayloadAction<number>) {
      if (state.currentLecture?.video && state.course) {
        state.currentLecture.video.realPlayedTime = action.payload;
        updateCourseLectures(state, state.currentLecture.lectureId, state.currentLecture);
      }
    },
    changeCurrentLecture(state, action: PayloadAction<number>) {
      if (!state.course) return;
      const lectureId = action.payload;
      const flatLectures = state.course.chapter.flatMap(chapter => chapter.lecture);

      // 강의 선택시, 강의 정보 업데이트
      if (lectureId !== state.currentLecture?.lectureId) {
        const lecture = flatLectures.find(item => item.lectureId === lectureId);
        if (lecture) {
          state.currentLecture = lecture;
        }
      }
    },
  },
});

function* watchSetSkillupList() {
  const { setSkillupList_Request, setSkillupList_Success, setSkillupList_Failure } =
    skillup.actions;

  yield takeLatest(setSkillupList_Request, function* (action) {
    try {
      const { data } = yield call(getSkillupListAPI, action.payload);

      yield put(setSkillupList_Success(data));
    } catch (err) {
      sendErrorToSentry(err);
      yield put(setSkillupList_Failure());
    }
  });
}

export function* skillupSaga() {
  yield all([fork(watchSetSkillupList)]);
}

export const skillupActions = { ...skillup.actions };

export default skillup;
