'use client';
import React, { useEffect } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { useAppSelector } from '#lib/hooks/useAppSelector';
import styled from 'styled-components';
import useModal from '#lib/hooks/useModal';
import responsive from '@miniintern/styles/responsive';
import ModalTemplate from './ModalTemplate';
import useAsPath from '#lib/useAsPath';

const ModalBlock = styled.div`
  .modal-wrapper {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    top: 0;
    left: 0;
    position: fixed;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 100px 0;
    overflow: scroll;

    &::-webkit-scrollbar {
      display: none;
      height: 0;
      width: 0;
    }

    @media (max-width: ${responsive.medium}) {
      padding: 50px 0;
    }
  }
`;

const Modal: React.FC = () => {
  const { asPath } = useAsPath();
  const modal = useAppSelector(state => state.core.modal);
  const toastOpened = useAppSelector(state => state.core.toaster.visible);
  const { closeModal } = useModal();

  useEffect(() => {
    closeModal();
  }, [asPath]);

  return (
    <ModalBlock id="popup-root">
      {modal.length > 0 &&
        modal.map((item, index) => {
          const { Component } = item;
          return (
            <div className="modal-wrapper" key={index}>
              <OutsideClickHandler
                onOutsideClick={() => {
                  //* 마지막 팝업만 닫히도록
                  if (index === modal.length - 1) {
                    closeModal();
                  }
                }}
                //* 토스트가 열려있거나 마지막 모달에 preventOutClick이 true이면 disabled
                disabled={toastOpened || !!item.preventOutClick}
              >
                <ModalTemplate key={index}>
                  <Component />
                </ModalTemplate>
              </OutsideClickHandler>
            </div>
          );
        })}
    </ModalBlock>
  );
};

export default Modal;
