import React, { createElement, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import palette from '@miniintern/styles/palette';

const slideDownPC = keyframes`
  0% {
    top: 0px;
    opacity: 0;
  }
  15% {
    top: 80px;
    opacity: 1;
  }
  85% {
    top: 80px;
    opacity: 1;
  }
  100% {
    top: 80px;
    opacity: 0;
  }
`;

const slideDownMobile = keyframes`
  0% {
    top: 0px;
    opacity: 0;
  }
  15% {
    top: 20px;
    opacity: 1;
  }
  85% {
    top: 20px;
    opacity: 1;
  }
  100% {
    top: 20px;
    opacity: 0;
  }
`;

const ToastBlock = styled.div<{ visible: boolean }>`
  z-index: ${props => (props.visible ? 9999 : -9999)};
  display: flex;
  width: 340px;
  min-width: 300px;
  max-width: 340px;
  padding: 16px;
  align-items: center;
  gap: 8px;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 8px;
  border: 1px solid ${palette.gray_400};
  background: ${palette.white};
  box-shadow: 0px 4px 8px 0px rgba(26, 27, 28, 0.12);
  animation: ${props => (props.visible ? slideDownPC : 'none')} 2s ease-out;
  opacity: 0;
  user-select: none;

  @media (max-width: 768px) {
    animation: ${props => (props.visible ? slideDownMobile : 'none')} 2s ease-out;
  }

  .toast-block-icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .toast-block-message {
    white-space: pre-wrap;
    cursor: default;
  }
`;

export type ToasterType = 'success' | 'info' | 'error' | 'warn' | null;

interface ToastProps {
  type: ToasterType;
  message: string;
  visible: boolean;
  closeToast: () => void;
}

const Toast: React.FC<ToastProps> = ({ type, message, visible, closeToast }) => {
  let iconNode: React.ReactNode = null;

  if (type) {
    iconNode = createElement(typeToIcon[type] || null);
  }

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (visible) {
      timer = setTimeout(() => {
        closeToast();
      }, 2000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [visible, closeToast]);

  return (
    <ToastBlock role="alert" visible={visible} data-testid="toast">
      <div className="toast-block-icon">{iconNode}</div>
      <div className="toast-block-message">{message}</div>
    </ToastBlock>
  );
};

export default React.memo(Toast);

const ToastSuccessIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="Icon/toast-success">
      <path
        id="Vector"
        d="M12 22.5C17.799 22.5 22.5 17.799 22.5 12C22.5 6.20101 17.799 1.5 12 1.5C6.20101 1.5 1.5 6.20101 1.5 12C1.5 17.799 6.20101 22.5 12 22.5Z"
        fill="#1BC481"
      />
      <path
        id="Vector_2"
        d="M15.2207 9.21973L16.2812 10.2802L11.251 15.3105L7.7207 11.7802L8.7812 10.7197L11.251 13.1887L15.2207 9.21973Z"
        fill="white"
      />
    </g>
  </svg>
);

const ToastErrorIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="Icon/toast-error">
      <path
        id="Vector"
        d="M12 22.5C17.799 22.5 22.5 17.799 22.5 12C22.5 6.20101 17.799 1.5 12 1.5C6.20101 1.5 1.5 6.20101 1.5 12C1.5 17.799 6.20101 22.5 12 22.5Z"
        fill="#FF0322"
      />
      <path
        id="Vector_2"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.25 11.25H15.75V12.75H8.25V11.25Z"
        fill="white"
      />
    </g>
  </svg>
);

const ToastInfoIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="Icon/toast-info">
      <path
        id="Vector"
        d="M12 22.5C17.799 22.5 22.5 17.799 22.5 12C22.5 6.20101 17.799 1.5 12 1.5C6.20101 1.5 1.5 6.20101 1.5 12C1.5 17.799 6.20101 22.5 12 22.5Z"
        fill="#17A1FF"
      />
      <path
        id="Vector_2"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.75 16.5V10.5H11.25V16.5H12.75ZM12.75 9V7.5H11.25V9H12.75Z"
        fill="white"
      />
    </g>
  </svg>
);

const ToastWarnIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="Icon/toast-warn">
      <path
        id="Polygon 1"
        d="M11.1318 2.51943C11.5157 1.84761 12.4843 1.84761 12.8682 2.51943L23.1451 20.5039C23.526 21.1705 23.0446 22 22.2768 22H1.72318C0.955356 22 0.47399 21.1705 0.854937 20.5039L11.1318 2.51943Z"
        fill="#ECBD00"
      />
      <path
        id="Vector"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.75 15V9H11.25V15H12.75ZM12.75 18V16.5H11.25V18H12.75Z"
        fill="white"
      />
    </g>
  </svg>
);

const typeToIcon = {
  success: ToastSuccessIcon,
  info: ToastInfoIcon,
  error: ToastErrorIcon,
  warn: ToastWarnIcon,
};
