'use client';

import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import useModal from '#lib/hooks/useModal';
import { getScrollbarWidth, isAndroid } from '@miniintern/utils';

const ModalTemplateBlock = styled.div``;
interface IProps {
  children: React.ReactNode;
  preventRouting?: boolean;
}

const ModalTemplate: React.FC<IProps> = ({ children, preventRouting = false }) => {
  const modalTempleteRef = useRef<HTMLDivElement>(null);
  const router = useRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const { closeModal } = useModal();
  useEffect(() => {
    //? 모바일에서 팝업 외부 스크롤이 흔들리지 않기 위해
    const scrollPosition = window.pageYOffset;
    const scrollWidth = getScrollbarWidth();

    document.body.style.overflow = 'hidden';
    document.body.style.paddingRight = `${scrollWidth}px`;

    const routeChangeStart = () => {
      const isFullScreenAtMobile =
        modalTempleteRef.current?.children[0].clientHeight === window.innerHeight;
      if (preventRouting || !isFullScreenAtMobile || !isAndroid) return;
      closeModal();
      router.replace(pathname + (searchParams?.toString() || ''));
      throw 'Abort route change. Please ignore this error.';
    };

    router.events.on('routeChangeStart', routeChangeStart);
    return () => {
      // document.body.style.overflow = 'auto';
      // document.body.style.paddingRight = '0';
      document.body.style.removeProperty('overflow');
      document.body.style.removeProperty('padding-right');
      window.scrollTo(0, scrollPosition);
      routeChangeStart && router.events.off('routeChangeStart', routeChangeStart);
    };
  }, []);

  return (
    <ModalTemplateBlock ref={modalTempleteRef}>
      <>{children}</>
    </ModalTemplateBlock>
  );
};

export default React.memo(ModalTemplate);
