import { useMemo } from 'react';
import { onEmailLoginAPI } from '../api/auth';
import useToaster from './useToaster';
import routes from '../../_constants/routes';
import useAsPath from '#lib/useAsPath';
import { secedersSubSocialAccountAPI } from '#lib/api/me';

const useLogin = () => {
  const { asPath } = useAsPath();
  const { openToast } = useToaster();

  const successRedirectUrl = useMemo(() => {
    if (typeof window === 'undefined') return routes.home;
    const routerHistoryStorage = window.localStorage.getItem('routerHistory');
    const routerHistory = routerHistoryStorage
      ? (JSON.parse(routerHistoryStorage) as string[])
      : [];
    const prevRouterExceptedPathnameAuth =
      routerHistory.find(history => !history.includes('auth') && !history.includes('secession')) ||
      '';
    return `${process.env.CLIENT_URL}${prevRouterExceptedPathnameAuth}`;
  }, []);

  interface SocialLoginType {
    mode: 'signup' | 'login' | 'link';
    isAdsAgreed?: boolean;
    socialName: 'kakao' | 'facebook' | 'google' | 'naver';
  }

  const emailLogin = async (email: string, password: string, isModal: boolean) => {
    if (!email || !password) return;
    try {
      await onEmailLoginAPI({ email, password });
      if (isModal) {
        window.location.reload();
        return;
      }
      window.location.href = successRedirectUrl;
    } catch (err) {
      openToast({ type: 'error', message: err.response.data });
    }
  };

  const socialLogin = async ({ mode, isAdsAgreed, socialName }: SocialLoginType) => {
    const failureRedirectUrl = `${process.env.CLIENT_URL}${asPath}`;
    const signupRedirectUrl = `${process.env.CLIENT_URL}/auth/signup`;

    window.location.href = `${process.env.API_URL}/api/v2/auth/${socialName}?successRedirectUrl=${successRedirectUrl}&failureRedirectUrl=${failureRedirectUrl}&signupRedirectUrl=${signupRedirectUrl}&isAdsAgreed=${isAdsAgreed}&mode=${mode}`;
  };

  const linkSocialAccount = async (socialName: 'kakao' | 'naver') => {
    try {
      await socialLogin({ mode: 'link', socialName });
    } catch (error) {
      openToast({
        type: 'error',
        message: '소셜 계정 연동 중 오류가 발생했어요.\n오류가 반복될 경우 고객센터에 문의주세요.',
      });
    }
  };

  const unlinkSocialAccount = async (socialName: 'kakao' | 'naver') => {
    try {
      const response = await secedersSubSocialAccountAPI({ socialName });

      window.location.href = response.data;
    } catch (error) {
      openToast({
        type: 'error',
        message:
          '소셜 계정 연동 해제 중 오류가 발생했어요.\n오류가 반복될 경우 고객센터에 문의주세요.',
      });
    }
  };

  return { emailLogin, socialLogin, linkSocialAccount, unlinkSocialAccount };
};

export default useLogin;
