'use client';

import React, { useEffect } from 'react';
import { useAppSelector } from '#lib/hooks/useAppSelector';
import useToaster from '#lib/hooks/useToaster';
import { Toast } from '@miniintern/ui';

const ClientToast: React.FC = () => {
  const { closeToast } = useToaster();
  const toaster = useAppSelector(state => state.core.toaster);

  useEffect(() => {
    return () => {
      if (typeof toaster.fn === 'function') {
        toaster.fn();
        return;
      }
    };
  }, [toaster]);

  return (
    <Toast
      type={toaster.type}
      message={toaster.message}
      visible={toaster.visible}
      closeToast={closeToast}
    />
  );
};

export default React.memo(ClientToast);
