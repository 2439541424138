import axios from './apiClient';
import { EmailRegisterParams, ResetPasswordParams } from '#types/apiParams';
import { EmailLoginAPIResponse } from '#types/apiResponse';
import routes from '#constants/routes';

//* 회원가입 하기
export const emailRegister = ({ email, name, key, password, isAdsAgreed }: EmailRegisterParams) =>
  axios.post('/api/v2/auth/register', {
    email,
    name,
    password,
    key,
    isAdsAgreed,
  });

export type EmailLoginParams = {
  email: string;
  password: string;
};

//* 이메일 로그인
export const onEmailLoginAPI = ({ email, password }: EmailLoginParams) =>
  axios.post<EmailLoginAPIResponse>('/api/v2/auth/login', { email, password });

//* 이메일 존재여부 체크하기
export const checkEmailExists = ({ email }: { email: string }) =>
  axios.post<{ duplicated: boolean }>('/api/v2/auth/email/check/duplications', {
    email,
  });

//* 키값 체크
export const checkIsExistedKeyAPI = ({ key }: { key: string }) =>
  axios.post('/api/v2/auth/email/keys/exists', { key });

//* 이메일 인증 메일 보내기
export const sendEmailAuthentication = ({
  currentEmail,
  emailToBeVerified,
  redirectUrl = routes.home,
  isAdsAgreed,
}: {
  currentEmail?: string;
  emailToBeVerified: string;
  redirectUrl?: string;
  isAdsAgreed?: boolean;
}) =>
  axios.post('/api/v2/auth/email/send/verification', {
    currentEmail,
    emailToBeVerified,
    redirectUrl,
    isAdsAgreed,
  });

//* 이메일 변경
export const updateUserEmailAPI = ({
  currentEmail,
  verifiedEmail,
}: {
  currentEmail: string;
  verifiedEmail: string;
}) => axios.put('/api/v2/auth/email', { currentEmail, verifiedEmail });

//* 인증 요청
export const verifyUserAPI = ({ userEmail, key }: { userEmail: string; key: string }) =>
  axios.post('/api/v2/auth/verify', { userEmail, key });

//* 비밀번호 찾기 이메일 보내기
export const sendEmail = (email: string) => axios.post('api/v2/password-reset', { email });

//* 비밀번호 초기화 하기
export const resetPassword = ({ key, password }: ResetPasswordParams) =>
  axios.put('api/v2/password-reset', { key, password });

//* 네이버 로그인 재시도
export const repromptLoginWithNaverAPI = () => axios.get('/api/v2/auth/naver/reprompt');
