import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';
import { useRouter, useSearchParams } from 'next/navigation';
import palette from '@miniintern/styles/palette';
import responsive from '@miniintern/styles/responsive';
import { Button } from '@miniintern/ui';
import GoogleLogoSVG from '@svg/sns-icon/logo-google.svg';
import NaverLogoSVG from '@svg/sns-icon/login-logo_naver.svg';
import FacebookLogoSVG from '@svg/sns-icon/logo-facebook.svg';
import NaverCheckedBox from '@svg/naver-preview-check-box/naver_checked.svg';
// import AuthLine from '../AuthLine';

import { repromptLoginWithNaverAPI } from '#lib/api/auth';
import useToaster from '#lib/hooks/useToaster';
import useLogin from '#lib/hooks/useLogin';
import PopupContainer from '#components/common/popupBox/PopupContainer';
import routes from '#constants/routes';
import { makeUrlQueries } from '@miniintern/utils';
import { sendErrorToSentry } from '#lib/error';

const FailureNaverLoginPopupBlock = styled(PopupContainer)`
  .failure-naver-login-popup-main-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    button svg {
      margin-right: 12px;
    }

    .failure-naver-login-popup-main-descripion {
      text-align: center;
      margin: 24px 0;

      @media (max-width: ${responsive.small}) {
        margin: 32px 0;
      }
    }

    .failure-naver-login-popup-preview-wrapper {
      width: 100%;
      border: 1px solid ${palette.gray_400};
      display: flex;
      flex-direction: column;
      padding: 16px;

      .failure-naver-login-popup-preview-title {
        font-weight: 500;
        margin-bottom: 4px;
      }

      .failure-naver-login-popup-preview-description {
        color: ${palette.gray_600};
        margin-bottom: 17px;
      }

      .failure-naver-login-popup-check-box-wrapper {
        display: flex;

        div {
          width: 50%;
          display: flex;
          align-items: center;
          span {
            color: ${palette.gray_600};
            margin-left: 6px;
          }
        }
      }
    }

    .failure-naver-login-popup-another-desc {
      color: ${palette.gray_500};
      padding: 32px 0 16px 0;

      @media (max-width: ${responsive.small}) {
        padding: 20px 0 12px 0;
      }
    }
  }
`;
const getColorStyle = (color: 'google' | 'facebook' | 'naver' | 'kakao') => {
  switch (color) {
    case 'google':
      return css`
        border-color: ${palette.gray_400};
        background: transparent;
        color: ${palette.black};
        &:hover {
          border-color: ${palette.gray_400};
          background-color: ${palette.gray_200};
        }
      `;
    case 'facebook':
      return css`
        border-color: ${palette.gray_400};
        background: transparent;
        color: ${palette.black};
        &:hover {
          color: white;
          border-color: ${palette.blue_fb};
          background-color: ${palette.blue_fb};
        }
      `;

    case 'naver':
      return css`
        border-color: ${palette.gray_400};
        background: transparent;
        color: ${palette.black};
        &:hover {
          color: white;
          border-color: ${palette.green_naver};
          background: ${palette.green_naver};
        }
      `;

    case 'kakao':
      return css`
        color: ${palette.black};
        border-color: ${palette.yellow_kakao};
        background: ${palette.yellow_kakao};
      `;
  }
};
const CustomButton = styled.button<{ color: 'google' | 'facebook' | 'naver' | 'kakao' }>`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 5px;
  border-width: 1px;
  border-style: solid;
  border-color: ${palette.blue_500};
  background: ${palette.blue_500};
  color: white;
  cursor: pointer;
  outline: none;
  user-select: none;
  transition: all 0.2s ease-in-out;

  width: 100%;
  min-width: 86px;
  height: 46px;
  padding: 0px 20px;
  font-size: 16px;

  ${({ color }) => getColorStyle(color)}
`;

const FailureNaverLoginPopup = () => {
  const router = useRouter();
  const searchParams = useSearchParams();
  const step = searchParams?.get('step');
  const { openToast } = useToaster();
  const { socialLogin } = useLogin();

  const loginWithNaver = async () => {
    try {
      const { data } = await repromptLoginWithNaverAPI();
      window.location.href = data;
    } catch (err) {
      sendErrorToSentry(err);
      openToast({
        message: err?.data || err.stateText,
        type: 'error',
      });
    }
  };

  const onClickSignupButton = (provider: 'google' | 'naver' | 'facebook') => {
    const isAdsAgreed = JSON.parse(window.localStorage.getItem('isAdsAgreed')!);
    socialLogin({
      mode: 'signup',
      socialName: provider,
      isAdsAgreed,
    });
  };

  //* 정보입력 탭에서 localStorage에 isAdsAgreed가 없으면 초기화면으로 전환
  useEffect(() => {
    if (!step) return;
    const isAdsAgreed = window.localStorage.getItem('isAdsAgreed');
    if (!isAdsAgreed) {
      router.replace(routes.signup);
      openToast({
        message: '잘못된 접근 입니다.\n약관동의 후 다시 진행해 주세요.',
        type: 'error',
      });
    }
  }, []);

  return (
    <FailureNaverLoginPopupBlock
      tag="미니인턴 가입"
      title="다시 시도해 주세요."
      isFullScreenAtMobile
    >
      <div className="failure-naver-login-popup-main-wrapper">
        <span className="failure-naver-login-popup-main-descripion">
          네이버로 가입하기 위해서 기본정보 및 필수 제공 항목
          <br /> 수집 동의가 필요합니다. 아래 이미지처럼 이름과 이메일
          <br /> 모두 체크한 후 동의하기를 눌러주세요.
        </span>
        <div className="failure-naver-login-popup-preview-wrapper">
          <span className="failure-naver-login-popup-preview-title">기본 정보</span>
          <span className="failure-naver-login-popup-preview-description">이용자 고유 식별자</span>
          <span className="failure-naver-login-popup-preview-title">필수 제공 항목</span>
          <div className="failure-naver-login-popup-check-box-wrapper">
            <div className="failure-naver-login-popup-check-box-wrapper-asdf">
              <NaverCheckedBox />
              <span>이름</span>
            </div>
            <div>
              <NaverCheckedBox />
              <span>이메일</span>
            </div>
          </div>
        </div>
        <CustomButton color="naver" style={{ marginTop: '8px' }} onClick={loginWithNaver}>
          <NaverLogoSVG />
          네이버로 가입하기
        </CustomButton>

        <p className="failure-naver-login-popup-another-desc">다른 방법으로 가입하려면</p>

        <Button
          buttonType="primary"
          color="blue_500"
          size="xLarge"
          onClick={() =>
            router.push(makeUrlQueries(routes.signup, { step: 'email-authentication' }))
          }
        >
          이메일로 가입하기
        </Button>
        <CustomButton
          color="google"
          style={{ marginTop: '12px' }}
          onClick={() => onClickSignupButton('google')}
        >
          <GoogleLogoSVG />
          Google로 가입하기
        </CustomButton>
        <CustomButton
          color="facebook"
          style={{ marginTop: '12px' }}
          onClick={() => onClickSignupButton('facebook')}
        >
          <FacebookLogoSVG />
          페이스북으로 가입하기
        </CustomButton>
      </div>
    </FailureNaverLoginPopupBlock>
  );
};

export default React.memo(FailureNaverLoginPopup);
