'use client';

import React, { useEffect } from 'react';
import { usePathname } from 'next/navigation';
import Toaster from '#components/common/Toaster';
import Modal from '#components/common/Modal/Modal';
import LoginErrorHandler from '#components/common/LoginErrorHandler';
import useAsPath from '#lib/useAsPath';
import { useRouter } from 'next/navigation';

const CoreContainer: React.FC = () => {
  const pathname = usePathname();
  const router = useRouter();
  const { asPath } = useAsPath();

  const removeFacebookAppendedHash = () => {
    // https://github.com/jaredhanson/passport-facebook/issues/12
    // 패스포트 페이스북 로그인 관련 이슈 7년째 해결되지 않음...

    const { hash } = window.location;
    if (hash === '#_=_') {
      // when facebook login, remove hashs
      router.replace(asPath.replace(hash, ''));
    }
  };

  const setRouterHistoryInlocalStorage = () => {
    const localStorageRouterHistory = window.localStorage.getItem('routerHistory');
    const prevRouterHistory = localStorageRouterHistory
      ? JSON.parse(localStorageRouterHistory)
      : [];
    //* 중복 제거
    if (prevRouterHistory[0] !== pathname) {
      window.localStorage.setItem(
        'routerHistory',
        //* 너무 안길어지도록 slice
        JSON.stringify([pathname, ...prevRouterHistory.slice(0, 20)]),
      );
    }
  };

  useEffect(() => {
    setRouterHistoryInlocalStorage();
  }, [pathname]);

  useEffect(() => {
    removeFacebookAppendedHash();
  }, []);

  return (
    <>
      <Toaster />
      <Modal />
      <LoginErrorHandler />
    </>
  );
};

export default React.memo(CoreContainer);
